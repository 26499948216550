<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card class="cardClassDetailInfo" :noHeader="true">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="사업장" :value="param.plantName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비유형" :value="param.equipmentTypeName"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비관리번호" :value="param.equipmentNo"></c-label-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <c-label-text title="설비명" :value="param.equipmentName"></c-label-text>
          </div>
        </template>
      </c-card>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-tab
        type="tabcard"
        :tabItems.sync="tabItems"
        :inlineLabel="true"
        v-model="tab"
        align="left"
      >
        <template v-slot:default="tab">
          <component
            :is="tab.component"
            :popupParam="tab.param"
          />
        </template>
      </c-tab>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'equipment-grade',
  props: {
    param: {
      type: Object,
      default: () => ({
        equipmentCd: '',
        equipmentNo: '',
        plantName: '',
        plantCd: '',
        processCds: [],
        equipmentTypeName: '',
        equipmentName: '',
      }),
    },
  },
  data() {
    return {
      tab: 'devicePartCd1',
      tabItems: [],
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      this.listUrl = selectConfig.mdm.equipment.psm.list.url;
      this.getList();
    },
    getList() {
      if (this.param.equipmentCd) {
        this.$http.url = this.listUrl;
        this.$http.param = {equipmentCd: this.param.equipmentCd};
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          if(_result.data) {
            this.tabSetting(_result.data);
          }
        },);
      }
    },
    tabSetting(_data) {
      this.tabItems = [];
      let cnt = 1;
      this.$_.forEach(_data, item => {
        let pageurl = '';
        let _param = {};
        if (item.ptype == 'power') {
          pageurl = import(`${'@/pages/psi/pfi/powerMachineDetail.vue'}`)
          _param = {popeditable: false, psiPowerEquipmentId:item.pid};
        } else if (item.ptype == 'device') {
          pageurl = import(`${'@/pages/psi/pfi/deviceEquipmentDetail.vue'}`)
          _param = {popeditable: false, psiDeviceEquipmentId:item.pid};
        } else if (item.ptype == 'valve') {
          pageurl = import(`${'@/pages/psi/pfi/safetyValveRuptureDiskDetail.vue'}`)
          _param = {popeditable: false, psiValveRuptureId:item.pid};
        }
        this.tabItems.push({ name: 'psmtab' + cnt, icon: 'info', label:item.pname, param: _param, component: () => pageurl });
        cnt++;
      });
      if (_data.length > 0) {
        this.tab = 'psmtab1';
      }
    }
  }
};
</script>
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c(
          "c-card",
          { staticClass: "cardClassDetailInfo", attrs: { noHeader: true } },
          [
            _c("template", { slot: "card-detail" }, [
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "사업장", value: _vm.param.plantName },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "설비유형",
                      value: _vm.param.equipmentTypeName,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: {
                      title: "설비관리번호",
                      value: _vm.param.equipmentNo,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                {
                  staticClass: "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                },
                [
                  _c("c-label-text", {
                    attrs: { title: "설비명", value: _vm.param.equipmentName },
                  }),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
      [
        _c("c-tab", {
          attrs: {
            type: "tabcard",
            tabItems: _vm.tabItems,
            inlineLabel: true,
            align: "left",
          },
          on: {
            "update:tabItems": function ($event) {
              _vm.tabItems = $event
            },
            "update:tab-items": function ($event) {
              _vm.tabItems = $event
            },
          },
          scopedSlots: _vm._u([
            {
              key: "default",
              fn: function (tab) {
                return [
                  _c(tab.component, {
                    tag: "component",
                    attrs: { popupParam: tab.param },
                  }),
                ]
              },
            },
          ]),
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }